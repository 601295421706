"use server";

import { getRequestEvent } from "solid-js/web";
import {
  getProcurementWalletBalance,
  getProductSearch,
  getUserProfile,
} from "../apis/server_apis";
import { SearchResults } from "../types/search";
import { UserProfile } from "../types/user";
import { getCookie } from "vinxi/http";
import { Cookie } from "~/types";
import { redirect } from "@solidjs/router";

export type ProcurementRouteData = {
  allBrands: SearchResults;
  userProfile: {
    name: string;
    logo: string;
  };
  balance: number;
};

export const getProcurementRouteData =
  async (): Promise<ProcurementRouteData> => {
    const requestEvent = getRequestEvent();

    const allBrands$P = getProductSearch({
      query: "",
      pagination: {
        limit: 100,
        pageNo: 0,
      },
    });
    const userProfile$P = getUserProfile();
    const balance$P = getProcurementWalletBalance();

    if (requestEvent) {
      let sid =
        requestEvent?.locals?.sid ??
        getCookie(requestEvent?.nativeEvent, Cookie.SessionId);
      if (!sid) {
        throw redirect("/login");
      }
    }

    const resolved = await Promise.all([allBrands$P, userProfile$P, balance$P]);

    const allBrands = resolved[0];
    const userProfile = resolved[1];
    const { balance } = resolved[2];

    let routeData = {
      allBrands,
      userProfile: {
        name:
          userProfile.firstName && userProfile.lastName
            ? `${userProfile.firstName} ${userProfile.lastName}`
            : "Team",
        logo: requestEvent?.locals.clientLogoUrl,
      },

      balance,
    };

    return routeData;
  };
